
//import { $, jQuery } from "jquery";
//import { TweenMax, ScrollToPlugin } from "gsap/all";

import $ from 'jquery';
import { smooth } from "./smoothScroll"

window.jQuery = $;
window.$ = $;

require('./source/parallax');

require('owl.carousel');
require("simplelightbox/dist/simple-lightbox.jquery");

const scrollToTop = () => {
    if ($(window).scrollTop() > 125) {
        $("#nav").css("position", "fixed")
        $("#nav").addClass("fixed")
    }
    else {
        $("#nav").css("position", "relative")
        $("#nav").removeClass("fixed")
    }
}

const hideOnScroll = () => {
    if ($(window).scrollTop() > 800) {
        $(".totop").css("opacity", "1")
        $(".totop").css("visibility", "visible")
    }
    else {
        $(".totop").css("opacity", "0")
        $(".totop").css("visibility", "collapse")
    }
}


const carouselOne = () => {
    const car = $('.new-collections')
    if (car.length != 0) {
        car.owlCarousel({
            loop: true,
            margin: 40,
            autoplay: true,
            items: 1,
            responsiveClass: true,
            navText: ['<span class="owl-prev left icon icon-arrow-left"></span>', '<span class="owl-next right icon icon-arrow-right"></span>'],
            responsive: {
                480: {
                    items: 1,
                    nav: true,
                    loop: true,
                },
                768: {
                    items: 2,
                    nav: false,
                    loop: true,
                },
                992: {
                    items: 4,
                    nav: true,
                    loop: false,
                    loop: true,
                },
                1200: {
                    items: 4,
                    nav: true,
                    loop: false,
                    loop: true,
                }
            }
        });
    }
}

const carouselTwo = () => {
    const carSales = $('.sales-products')
    if (carSales.length != 0) {
        carSales.owlCarousel({
            loop: true,
            margin: 40,
            autoplay: true,
            items: 1,
            responsiveClass: true,
            navText: ['<span class="owl-prev left icon icon-arrow-left"></span>', '<span class="owl-next right icon icon-arrow-right"></span>'],
            responsive: {
                480: {
                    items: 1,
                    nav: true,
                    loop: true,
                },
                768: {
                    items: 2,
                    nav: false,
                    loop: true,
                },
                992: {
                    items: 4,
                    nav: true,
                    loop: false,
                    loop: true,
                },
                1200: {
                    items: 4,
                    nav: true,
                    loop: false,
                    loop: true,
                }
            }
        });
    }
}

const hero = () => {
    const heroSlide = $('#desktop-slider')
    if (heroSlide.length != 0) {
        heroSlide.owlCarousel({
            loop: true,
            margin: 0,
            autoplay: true,
            items: 1,
            responsiveClass: true,
            nav: true,
            navText: ['<span class="owl-prev left icon icon-arrow-left"></span>', '<span class="owl-next right icon icon-arrow-right"></span>'],
        });
    }
}

const heroMobile = () => {
    const heroMobileSlide = $('#mobile-slider')
    if (heroMobileSlide.length != 0) {
        heroMobileSlide.owlCarousel({
            loop: true,
            margin: 0,
            autoplay: true,
            items: 1,
            responsiveClass: true,
            nav: true,
            navText: ['<span class="owl-prev left icon icon-arrow-left"></span>', '<span class="owl-next right icon icon-arrow-right"></span>'],
        });
    }
}


const lineCartSum = (id, quantity) => {
    if (quantity > 0 && id) {
        $.get("/cart/change-quantity", { 'id': id, 'quantity': quantity }).then(function (data) {
            $('#cart-product-price-' + id).html(data.price);
            $('#sum-price').html(data.sumPrice)
            $(".number-wrap .number").text(data.cartNr || '0');
            $.get("/cart/update-views").then(function (data) {
                $('#sidecart').html(data.sidecart);
            });
            if (data.discount) {
                $('#discount').html("-" + data.discount)
            }
        });
    }
    let quant = $(".counter").val();
    if (quant > 0 && id) {
        $.get("/cart/add", { 'id': id }).then(function (data) {
            $('#cartnumber').html(data.cartNr || '-')
        });
    }
}

const product = () => {
    const quantity = $('.quantity')
    const currentPage = $('#puma-cart-table')

    if (quantity.length != 0) {
        $(document).on("click", '.counter-btn', function (e) {
            e.preventDefault();
            const $btn = $(this);
            const storeID = $btn.data('index');
            let storeMax = $('#cart-product-quantity-' + $btn.data('index')).attr("max");
            $('#cart-product-quantity-' + $btn.data('index')).val((i, val) => {
                val = val * 1 + $btn.data('inc');
                if (val >= 1 && val <= storeMax) {
                    if (currentPage.length != 0) {
                        lineCartSum(storeID, val);
                    }
                    return (val <= 0 ? '' : '') + val;
                }
                else {
                    if ($btn.data('inc') === -1) {
                        if (currentPage.length != 0) {
                            lineCartSum(storeID, 1);
                        }
                        return 1;
                    }
                    else if ($btn.data('inc') === 1) {
                        if (currentPage.length != 0) {
                            lineCartSum(storeID, storeMax);
                        }
                        return storeMax;
                    }
                }
            });
        });
    }
}

const lightbox = () => {
    const singleProductLightbox = $('.single-product')

    if (singleProductLightbox.length != 0) {
        $('.product a').simpleLightbox({ alertError: false });
    }
}

const isMobile = () => {

    var screenWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    var screenHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    if (screenHeight > screenWidth) {
        return true
    }
    else {
        return false
    }
}

const detectScreen = (mobile) => {
    const desktopSlider = $('#desktop-slider');
    const mobileSlider = $('#mobile-slider');

    if (desktopSlider.length != 0 && mobileSlider.length != 0) {
        if (!mobile) {
            desktopSlider.css("display", "block")
            mobileSlider.css("display", "none")
        } else {
            desktopSlider.css("display", "none")
            mobileSlider.css("display", "block")
        }
    }
}

const toggleAddresses = () => {
    const payment = $('#payment')
    if (payment.length != 0) {
        const both = $('.shipping-options').find('.both');
        const userOnly = $('.shipping-options').find('.user-only');
        const companyOnly = $('.shipping-options').find('.company-only');
        const isCompanyRow = $('.is-company-row')
        const isCompany = $('#customer-is-company')

        const bothInput = $('.both-required');
        const userOnlyInput = $('.user-only-required');
        const companyOnlyInput = $('.company-only-required');

        if ($('#change-billing-address').is(':checked') && !$('#customer-is-company').is(':checked')) {
            both.addClass('show');
            userOnly.addClass('show');
            companyOnly.removeClass('show');

            isCompanyRow.addClass("show")

            bothInput.each(function () { $(this).attr("required", "true"); });
            userOnlyInput.each(function () { $(this).attr("required", "true"); });
            companyOnlyInput.each(function () { $(this).removeAttr("required"); });

        } else if ($('#change-billing-address').is(':checked') && $('#customer-is-company').is(':checked')) {
            both.addClass('show');
            userOnly.removeClass('show');
            companyOnly.addClass('show');

            bothInput.each(function () { $(this).attr("required", "true"); });
            userOnlyInput.each(function () { $(this).removeAttr("required"); });
            companyOnlyInput.each(function () { $(this).attr("required", "true"); });
        } else if (!$('#change-billing-address').is(':checked')) {
            both.removeClass('show');
            userOnly.removeClass('show');
            companyOnly.removeClass('show');

            isCompany.prop("checked", false)
            isCompanyRow.removeClass("show")

            bothInput.each(function () { $(this).removeAttr("required"); });
            userOnlyInput.each(function () { $(this).removeAttr("required"); });
            companyOnlyInput.each(function () { $(this).removeAttr("required"); });
        }
    }
}


const getFocus = (id) => {
    const element = document.getElementById(id);
    element.focus()
}

const getDocHeight = () => {
    var D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    );
}

$(function () {
    const para = $('.parallax');
    const toTop = $(".totop");

    smooth();
    hero();
    heroMobile();
    carouselOne();
    carouselTwo();
    product();
    detectScreen(isMobile());
    lightbox();

    if (para) {
        $('#sales-banner').parallax("50%")
        $('#news-banner').parallax("50%")
        $('#new-collection').parallax("50%")
    }

    $('.toggle-menu').on("click", () => {
        $('.puma-menu').toggleClass('display');
    })

    $('.search').on("click", () => {
        $('.topsearch').toggleClass('hidden');
        getFocus("search");
    });

    $('.notwrapper').on("click", () => {
        $('.topsearch').toggleClass('hidden');
    });

    $(window).on("scroll", () => {
        scrollToTop();
        hideOnScroll();
        if ($(window).scrollTop() + $(window).height() == getDocHeight()) {
            toTop.addClass("upper")
        } else {
            if (toTop.hasClass("upper")) {
                toTop.removeClass("upper")
            }
        }
    });
    $(window).on("click", () => {
        toggleAddresses();
    });
    $(window).on("resize", () => {
        scrollToTop();
        hideOnScroll();
        detectScreen(isMobile());
    });
    $('#top-cart-btn').on("click", () => {
        $("#sidecart").toggleClass('active');
    });
    $(document).on("mouseup", (e) => {
        const sidecart = $("#sidecart");
        if (!sidecart.is(e.target) && sidecart.has(e.target).length === 0) {
            sidecart.removeClass("active");
        }
    });
});


global.changeVariation = function changeVariation(productID) {
    $.get("/api/products/" + productID).then(function (data) {
        var product = data.product;
        $(".counter").attr("max", product.own_stock);
    });
}

global.changeShippingMethod = function changeShippingMethod() {
    var inputs = {
        'payment_type_id': $('input[name="checkout[order][payment_type_id]"]:checked').val(),
    };

    $.get("/checkout/update-order-summary-table", inputs).then(function (data) {
        $('#order-summary-table').empty().append(data.view);
    });
}

global.removeFromCart = function removeFromCart(id) {
    $.get("/cart/remove", { 'id': id }).then(function (data) {
        $(".number-wrap .number").text(data.cartNr || '0');
        $.get("/cart/update-views").then(function (data) {
            $('#sidecart').empty().append(data.sidecart);
            $('#puma-cart-table').empty().append(data.table);
        });
    });
}

global.addToCart = function addToCart() {
    const quantity = $(".counter").val();
    const id = $(":checked").val();
    const sizes = $(".size ul")

    if (quantity > 0 && id) {
        $('#product-notification').addClass("active");
        $('html, body').animate({ scrollTop: 0 }, 'fast');

        $.get("/cart/add", { 'id': id, 'quantity': quantity }).then(function (data) {
            $(".number-wrap .number").text(data.cartNr || '0');
            console.log($.get("/cart/update-views"))
            $.get("/cart/update-views").then(function (viewData) {
                console.log(viewData.sidecart);
                $('#sidecart').empty().append(viewData.sidecart);
            });
        });
    }
    else {
        sizes.addClass("alert")
        setTimeout(() => {
            sizes.removeClass("alert")
        }, 2500);

    }
}
